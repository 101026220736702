export const MOMENT_DATE_FORMAT = "DD/MM/YYYY";
export const FULL_MOMENT_DATE_FORMAT = "DD/MM/YYYY HH:mm";
export const DATE_FORMAT = "dd/MM/yyyy";
export const DATE_TIME_FORMAT = "dd/MM/yyyy h:mm aa";
export const SQL_DATE_FORMAT = "YYYY/MM/DD";
export const SHORT_DATE_TIME = "MM/YYYY"
export const FULL_SQL_DATE_FORMAT = "YYYY/MM/DD HH:mm:ss";
export const HASH_CODE_FORMAT = "YYYYMMDDHHmmss";
export const FILE_NAME_HASH = 'YYYYMMDDHHmmsszzz';
export const LANGUAGE_CODES = [{ code: 'en', name: 'English' }, { code: 'vi', name: 'Việt Nam' }];
export const DEFAULT_LANGUAGE = "vi";
export const MAX_CODE = 1000000;
export const GET_ALL_SIZE = 100000;
export const PAGE_SIZES = 30;
export const PAGING_PADDING = 10;

export const DATA_TYPE = {
  ACTION: 'action',
  SELECT: 'select',
  CHECKBOX: 'checkbox',
  DATE_TIME: 'datetime',
  HEADER: 'header',
  FOOTER: 'footer',
  SIGNATURE: 'signature',
  TEXT: 'text',
  LABEL: 'label',
  EDITOR: 'editor',
  MAIL: 'mail',
  PHONE: 'phone',
  SUGGESTION: 'suggestion',
  UPLOAD: 'upload',
  UPLOAD_ALBUM: 'upload_album',
  NUMBER: 'number',
  TAGINPUTS: 'taginputs',
  TABLE: 'table',
  CUSTOM: 'custom',
  COL2: 'col2'
}
export const ACTION_STATUSES = {
  NORMAL: 0,
  DELETED: 1,
  INSERTED: 2,
  UPDATED: 3
}
export const NOTIFY_STATUS = {
  NEW: 1,
  VIEW: 2,
  DONE: 3
}
export const VOUCHER_STATUS = {
  NEW: 0,
  INPROGRESS: 1,
  DONE: 2,
  CANCEL: 3,
  PENDING: 4 
}
export const APPROVAL_STATUS = {
  WAIT: 1,
  DONE: 2,
  DECLINE: 3,
  PENDING: 4
}
export const LEDGER_TYPES = {
  RECEIPT: 1,
  PAYMENT: 2
}
export const ACTION_BUTTON_TYPES = {
  SITE_MAP: {
    type: 'SITE_MAP',
    icon: 'fa fa-sitemap',
    color: 'success',
    tooltip: 'dark',
    text: 'Cây tổ chức'
  },
  LIST_VIEW: {
    type: 'LIST_VIEW',
    icon: 'icon-list',
    color: 'success',
    tooltip: 'dark',
    text: 'Xem danh sách'
  },
  VIEW: {
    type: 'VIEW',
    icon: 'icon-eye',
    color: 'default',
    tooltip: 'dark',
    text: 'Xem'
  },
  ADD: {
    type: 'ADD',
    icon: 'icon-plus',
    color: 'success',
    tooltip: 'success',
    text: 'Thêm'
  },
  SAVE: {
    type: 'SAVE',
    icon: 'icon-cloud-download',
    color: 'success',
    tooltip: 'success',
    text: 'Lưu'
  },
  EDIT: {
    type: 'EDIT',
    icon: 'fa fa-edit',
    color: 'primary',
    tooltip: 'info',
    text: 'Sửa'
  },
  REFRESH: {
    type: 'REFRESH',
    icon: 'icon-refresh',
    color: 'info',
    tooltip: 'info',
    text: 'Làm mới'
  },
  LOCK: {
    type: 'LOCK',
    icon: 'icon-lock',
    color: 'danger',
    tooltip: 'error',
    text: 'Khóa'
  },
  UNLOCK: {
    type: 'UNLOCK',
    icon: 'icon-lock-open',
    color: 'success',
    tooltip: 'success',
    text: 'Mở khóa'
  },
  CHANGEROLE: {
    type: 'CHANGEROLE',
    icon: 'icon-shield',
    color: 'warning',
    tooltip: 'warning',
    text: 'Phân quyền'
  },
  SALARYPAY: {
    type: 'SALARYPAY',
    icon: 'fas fa-gift',
    color: 'warning',
    tooltip: 'warning',
    text: 'Tính lương'
  },
  ADVANCESALARY: {
    type: 'ADVANCESALARY',
    icon: 'fa fa-money',
    color: 'danger',
    tooltip: 'danger',
    text: 'Tạm ứng lương'
  },
  BACK: {
    type: 'BACK',
    icon: 'icon-action-undo',
    color: 'default',
    tooltip: 'dark',
    text: 'Quay lại'
  },
  DELETE: {
    type: 'DELETE',
    icon: 'icon-trash',
    color: 'danger',
    tooltip: 'error',
    text: 'Xóa'
  },
  QUOTATION: {
    type: 'QUOTATION',
    icon: 'icon-plus',
    color: 'success',
    tooltip: 'dark',
    text: 'Thêm báo giá'
  },
  CONTRACT: {
    type: 'CONTRACT',
    icon: 'icon-plus',
    color: 'success',
    tooltip: 'dark',
    text: 'Thêm hợp đồng'
  },
  PAYMENT: {
    type: 'PAYMENT',
    icon: 'icon-plus',
    color: 'success',
    tooltip: 'dark',
    text: 'Thêm nộp phí'
  },
  SEND: {
    type: 'SEND',
    icon: 'fa fa-rocket',
    color: 'success',
    tooltip: 'success',
    text: 'Gửi đi'
  },
  SEARCH: {
    type: 'SEARCH',
    icon: 'fa fa-find',
    color: 'danger',
    tooltip: 'dark',
    text: 'Tìm kiếm'
  },
  CANCEL: {
    type: 'CANCEL',
    icon: 'fa fa-close',
    color: 'default',
    tooltip: 'dark',
    text: 'Hủy'
  },
  APPROVAL: {
    type: 'APPROVAL',
    icon: 'fas fa-file-signature',
    color: 'success',
    tooltip: 'dark',
    text: 'Duyệt'
  }
}
export const ROLES = {
  ADMIN: 'Admin',
  HUMAN_RESOURCE: 'HumanResource',
  STAFF: 'Staff',
  QUALITY_CONTROL: 'QualityControl',
  ACCOUNTANTS: 'Accountants',
  TECHNICIAN: 'Technician'
};
export const ROLE_TYPES = {
  ACCESS_ALL: 1,
  ACCESS_SPECIFIC: 2
}
export const ROLE_RIGHTS = [
  {
    role: ROLES.ADMIN,
    type: ROLE_TYPES.ACCESS_ALL
  },
  {
    role: ROLES.HUMAN_RESOURCE,
    type: ROLE_TYPES.ACCESS_SPECIFIC,
    rights: [
      {
        key: 'dashboard'
      },{
        key: 'notification'
      },{
        key: 'personalMail'
      },{
        key: 'approval'
      },{
        key: 'company'
      },{
        key: 'leaveTrack'
      },{
        key: 'salary'
      },{
        key: 'marketing'
      },{
        key: 'job'
      },{
        key: 'library'
      },{
        key: 'logout'
      }
    ]
  },
  {
    role: ROLES.ACCOUNTANTS,
    type: ROLE_TYPES.ACCESS_SPECIFIC,
    rights: [
      {
        key: 'dashboard'
      },{
        key: 'notification'
      },{
        key: 'personalMail'
      },{
        key: 'approval'
      },{
        key: 'company'
      },{
        key: 'leaveTrack'
      },{
        key: 'salary'
      },{
        key: 'accountants'
      },{
        key: 'job'
      },{
        key: 'library'
      },{
        key: 'logout'
      }
    ]
  },
  {
    role: ROLES.QUALITY_CONTROL,
    type: ROLE_TYPES.ACCESS_SPECIFIC,
    rights: [
      {
        key: 'dashboard'
      },{
        key: 'notification'
      },{
        key: 'personalMail'
      },{
        key: 'approval'
      },{
        key: 'company'
      },{
        key: 'leaveTrack'
      },{
        key: 'salary'
      },{
        key: 'marketing'
      },{
        key: 'job'
      },{
        key: 'library'
      },{
        key: 'logout'
      }
    ]
  },
  {
    role: ROLES.TECHNICIAN,
    type: ROLE_TYPES.ACCESS_SPECIFIC,
    rights: [
      {
        key: 'dashboard'
      },{
        key: 'notification'
      },{
        key: 'personalMail'
      },{
        key: 'approval'
      },{
        key: 'company'
      },{
        key: 'leaveTrack'
      },{
        key: 'salary'
      },{
        key: 'marketing'
      },{
        key: 'job'
      },{
        key: 'library'
      },{
        key: 'logout'
      }
    ]
  },
  {
    role: ROLES.STAFF,
    type: ROLE_TYPES.ACCESS_SPECIFIC,
    rights: [
      {
        key: 'dashboard'
      },{
        key: 'notification'
      },{
        key: 'personalMail'
      },{
        key: 'approval'
      },{
        key: 'company'
      },{
        key: 'leaveTrack'
      },{
        key: 'salary'
      },{
        key: 'marketing'
      },{
        key: 'job'
      },{
        key: 'library'
      },{
        key: 'logout'
      }
    ]
  }
]